import React from "react";
import DevelopmentPageTemplate from "../../templates/development-page";
import { Form } from "react-bootstrap";
import "../../threeplay.scss";

export default class Index extends React.Component {
  render() {
    var overview = (
      <>
        <p>
          Checkboxes should be used when allowing a user to select a combination of different options. The user can also select zero or all of the possible options.
        </p>
        <p>
          The checkbox control allows for three states: selected, unselected, and indeterminate. The indeterminate state comes into play when the checkbox contains a sublist of selections, some of which are selected, and some unselected.
        </p>
        <p>
          As the indeterminate checkbox will be rarely used, refer to Mozilla's <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#Indeterminate_state_checkboxes" rel="noopener noreferrer" target="_blank">documentation page on indeterminate checkboxes</a> for more information.
        </p>
        <h5>
          Exceptions
        </h5>
        <p>
          1) If you have to show more than 7 elements, consider adding or replacing the list of checkboxes with a Searchable Dropdown components (to be created).
        </p>
        <p>
          2) If only one element can be selected at a time and there are fewer than 7 elements, consider using a radio button instead of a checkbox.
        </p>
      </>
    );

    var codeString = `
    <Form>
      <Form.Check type="checkbox" label="Unchecked" />
      <Form.Check type="checkbox" defaultChecked="true" label="Checked" />
      <Form.Check type="checkbox" label="Disabled" disabled />
      <Form.Check type="checkbox" defaultChecked="true" label="Disabled and Checked" disabled />
      <Form.Check type="checkbox" label="Indeterminate" id="indeterminate" ref={ref => {if (ref) {ref.indeterminate = true}}} />
    </Form>
    `;

    var code = (
      <>
        <br />
        <Form>
            <Form.Check type="checkbox" label="Unchecked" />
            <Form.Check type="checkbox" defaultChecked="true" label="Checked" />
            <Form.Check type="checkbox" label="Disabled" disabled />
            <Form.Check type="checkbox" defaultChecked="true" label="Disabled and Checked" disabled />
            <Form.Check type="checkbox" label="Indeterminate" id="indeterminate" ref={ref => {if (ref) {ref.indeterminate = true}}} />
        </Form>
        <br />
      </>
    );

    var codes = [
      {
        code: code,
        codeLanguage: "html",
        codeString: codeString
      }
    ]

    var accessibilitySection = (
      <>
        <h5>Checkboxes Must Be Keyboard Navigable</h5>
        <p>Since a checkbox is an interactive control, it must be focusable and keyboard accessible. If the role is applied to a non-focusable element, use the tabindex attribute to change this. The expected keyboard shortcut for activating a checkbox is the Space key.</p>

        <h5>Make the Label Clickable</h5>
        <p>
          In the above examples, you may have noticed that you can toggle a checkbox by clicking on its associated <code>&lt;label&gt;</code> element as well as on the checkbox itself. This is a really useful feature of HTML form labels that makes it easier to click the option you want, especially on small-screen devices like smartphones. Beyond accessibility, this is another good reason to properly set up <code>&lt;label&gt;</code> elements on your forms.
        </p>
      </>
    );

    return (
      <DevelopmentPageTemplate
        title="Checkboxes"
        overview={overview}
        codes={codes}
        accessibilitySection={accessibilitySection}
      />
    );
  }
}
